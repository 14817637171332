import * as React from 'react'
import { graphql } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image"
import SEO from '../components/SEO';
import Layout from '../layouts/index';

const App = ({ data }) => {
  const { title } = data.markdownRemark.frontmatter;
  const { image } = data.markdownRemark.frontmatter;
  const { html } = data.markdownRemark;
  const { fluid } = data.markdownRemark.frontmatter.image.childImageSharp;
  return (
    <Layout bodyClass="page-app">
      <SEO title={title} />
      <div className="container">
        <div className="row justify-content-start">
          <header className="col-12">
            <h1 className="title">{title}</h1>
          </header>
          <div className="col-12 col-md-8">
            <div className="app app-single">
              <GatsbyImage fluid={fluid} className="img-fluid mb-2" alt={title} />
              <div className="content" dangerouslySetInnerHTML={{ __html: html }} />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        path
        image {
          childImageSharp {
            fluid(quality: 95, maxWidth: 750) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      html
    }
  }
`;

export default App;
